class Filter {

  constructor(filterEl, listEl) {
    this.filterEl = filterEl
    this.listEl = listEl
    this.previous = null
    this.listen()
  }

  listen() {
    if (this.filterEl.parentElement.getAttribute("data-filter") === 'false') return
    this.hideAll();
    this.filterEl.addEventListener('click', (ev) => {
      if (!ev.target.classList.contains('filter__button')) return
      // check if button is active
      const activeButton = this.filterEl.querySelector('.filter__button--active')
      if (activeButton && ev.target != activeButton) {
        activeButton.classList.remove('filter__button--active')
        ev.target.classList.add('filter__button--active')
      } else {
        ev.target.classList.add('filter__button--active')
      }
      if (this.previous && this.previous === ev.target) {
        ev.target.classList.remove('filter__button--active')
        this.previous = null
        this.hideAll(ev.target.dataset.value)
        return
      }
      const value = ev.target.dataset.value
      const items = [].slice.call(this.listEl.children)
      items.forEach(item => {
        if (item.dataset.tags.includes(value)) {
          item.classList.remove('hidden')
        } else {
          item.classList.add('hidden')
        }
      })
      this.previous = ev.target
    })
  }
  hideAll() {
    const items = [].slice.call(this.listEl.children)
    items.forEach(item => {
      item.classList.add('hidden')
    })
  }

}

export default Filter